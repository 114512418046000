import React from "react";
import logo from "../assets/logo.jpg";

const Header = () => {
  return (
    <div className="header">
      <a href="/">
        <img src={logo} alt="Roundabout Technologies" className="logo" />
      </a>
      <div style={{ flexGrow: 1 }}></div>
      <a href="/team" style={{ marginRight: "50px", fontSize: "21px" }}>
        Team
      </a>
      <a className="book-demo-btn" href="/contact">
        See a Demo
      </a>
    </div>
  );
};

export default Header;
