import React from "react";
import logo from "../assets/logo.jpg";
import collinImage from "../assets/collin.png";
import sabeekImage from "../assets/sabeek.png";
import "../main.css";
import Header from "../components/Header";

const Team = () => {
  return (
    <div className="page-content">
      <div className="shapy-background">
        <Header />
        <div className="team-container">
          <div className="team-member-box">
            <img src={collinImage} alt="Collin Barnwell" />
            <div className="team-member-content">
              <h2>Collin Barnwell</h2>
              <h3>CEO</h3>
              <p>
                Software leader with experience building and shipping internet
                connected IoT camera devices
              </p>
            </div>
          </div>
          <div className="team-member-box">
            <img src={sabeekImage} alt="Sabeek Pradhan" />
            <div className="team-member-content">
              <h2>Sabeek Pradhan</h2>
              <h3>CTO</h3>
              <p>
                Autonomous Vehicle industry veteran with extensive experience
                building real-time computer vision systems for vehicle and
                pedestrian detection and tracking
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img
          style={{ margin: "auto 0" }}
          src={logo}
          alt="Roundabout Technologies"
          className="logo"
        />
        <a href="/contact" className="footer-link">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Team;
