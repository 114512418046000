import React from "react";
import logo from "../assets/logo.jpg";
import diagram from "../assets/diagram.svg";
import Header from "../components/Header";

const Home = () => {
  return (
    <div className="page">
      <div className="panel panel1">
        <div className="overlay"></div>
        <Header />
        <div className="first-panel-content">
          <h1>Human-centric traffic intersections, powered by AI</h1>
          <p className="subtitle">
            Roundabout eliminates unnecessary waiting, reduces emissions and
            creates a more pedestrian-friendly environment.
          </p>
        </div>
      </div>
      <div className="panel panel2 shapy-background">
        <div className="flex-content">
          <div>
            <img
              src={diagram}
              style={{ maxWidth: "100%", height: "auto" }}
              alt="Traffic Light Sensor Diagram"
            />
          </div>
          <div
            style={{
              textAlign: "left",
              maxWidth: "800px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1 style={{ textAlign: "left" }}>
              A Fully-Automated Sensor-Driven Traffic Solution
            </h1>
          </div>
        </div>
      </div>
      <div className="panel panel3">
        <div className="overlay"></div>
        <div className="flex-content">
          <div style={{ maxWidth: "800px", marginBottom: "100px" }}>
            <h1 style={{ textAlign: "left" }}>
              Roundabout isn't like other traffic cameras.
            </h1>
            <p>
              More than simply detecting the presence of vehicles, Roundabout
              anticipates the movements of vehicles, bikers, and pedestrians to
              optimize signal changes in real-time, and in doing so helps your
              city:
            </p>
          </div>
          <div
            style={{
              maxWidth: "800px",
              marginTop: "100px",
              paddingBottom: "200px",
            }}
          >
            <ul className="custom-list" style={{ textAlign: "left" }}>
              <li>Immediately reduce traffic emissions by over 10%</li>
              <li>
                Make your intersections more pleasant for pedestrians & bikers
              </li>
              <li>
                Prioritize buses & emergency vehicles with no additional
                hardware
              </li>
              <li>Save hours of cumulative wait times for all road users</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="panel shapy-background panel4">
        <h1>We'd love to show you what we're building</h1>
        <a className="book-demo-btn" href="/contact">
          See a Demo
        </a>
      </div>
      <div className="footer">
        <img
          style={{ margin: "auto 0" }}
          src={logo}
          alt="Roundabout Technologies"
          className="logo"
        />
        <a href="/contact" className="footer-link">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Home;
