import React from "react";
import logo from "../assets/logo.jpg";
import Header from "../components/Header";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <div className="page-content">
      <div className="panel shapy-background">
        <Header />
        <ContactForm />
      </div>
      <div className="footer">
        <img
          style={{ margin: "auto 0" }}
          src={logo}
          alt="Roundabout Technologies"
          className="logo"
        />
        <a href="/contact" className="footer-link">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Contact;
